import React from "react";
import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category="Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;


export const push_gtm_conversion = () => {
  console.log('pushing tag')
  // Check if dataLayer is available
  window.dataLayer = window.dataLayer || [];
  
  // Push the event into the dataLayer
  window.dataLayer.push({
    event: 'button_click_conversion', 
    button_text: 'Call|Submit',
  });
};


export const click_to_send_conversion = () => {
  const callback = () => {
    // if (typeof url !== 'undefined') {
    //   window.location = url;
    // }
  };

  // Push the conversion event to the dataLayer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'conversion',
    send_to: 'AW-16667117574/PP_gCMDwpfkZEIaQwIs-',
    event_callback: callback,
  });

  return false;
};

export const confirm_conversion = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'conversion',
    send_to: 'AW-16667117574/PP_gCMDwpfkZEIaQwIs-',
  });
  
  return false;
};